<template>
  <!-- <img class="rcc-image" :src="require('@/assets/RCC-color.png')" alt="Descripción de la imagen" /> -->
  <section class="nav">
    <NavbarComponente />
    <div class="txt-administracion">
      ADMINISTRACIÓN <br>
      CONDOMINAL
    </div>
    <div class="txt-cuidamos">
      <h2>CUIDAMOS Y PROTEGEMOS TU PATRIMONIO</h2>
    </div>
  </section>

  <section id="quienes-somos" class="quienessomos pt-4">
    <div class="d-flex justify-content-start p-5">
      <h3 class="txt-orange-quienes">¿QUIÉNES SOMOS?</h3>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <img class="img-quienes" :src="require('@/assets/logocirculo.png')" alt="Riviera Condo Care" />
        </div>
        <div class="col-md-6 txt-white-quienes p-3">
          En Riviera Condo Care nos preocupamos por el cuidado, protección y mantenimiento de tu patrimonio.
          Tenemos más de 5 años de experiencia en administración condominal y más de 10 años de experiencia en el
          servicio al cliente.
        </div>
      </div>
    </div>
  </section>

  <section id="servicios" class="servicios">
    <h2 class="d-flex justify-content-start p-5">
      SERVICIOS
    </h2>
    <CarouselServicios />
  </section>
 
  <section id="equipo" class="equipo">
    <div class="p-5 d-flex justify-content-start">
      <h2>
        <span class="txt-white-equipo">
          NUESTRO
        </span>
        EQUIPO
      </h2>
    </div>
    <TeamCarousel />
  </section>


  <section id="valores" class="valores">
    <div class="p-5 d-flex justify-content-start">
      <h2 class="txt-v">
        NUESTROS
        <span class="txt-orange-valores">
          VALORES
        </span>
      </h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="d-flex mb-5">
            <img :src="require('@/assets/ojo.png')" alt="Imagen 1" class="img-fluid me-3" style="width: 100px;">
            <div>
              <h4 class="txt-v txt-valores-title">TRANSPARENCIA</h4>
              <p class="txt-v">Actuamos con honestidad</p>
            </div>
          </div>
          <div class="d-flex mb-5">
            <img :src="require('@/assets/manos.png')" alt="Imagen 2" class="img-fluid me-3" style="width: 100px;">
            <div>
              <h4 class="txt-v txt-valores-title">COMPROMISO</h4>
              <p class="txt-v">Tenemos total dedicación hacia el logro de los objetivos</p>
            </div>
          </div>
          <div class="d-flex mb-5">
            <img :src="require('@/assets/planta.png')" alt="Imagen 2" class="img-fluid me-3" style="width: 100px;">
            <div>
              <h4 class="txt-v txt-valores-title">SOSTENIBILIDAD</h4>
              <p class="txt-v">Proponemos y proclamamos siempre el equilibrio entre lo económico, social y ambiental</p>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="d-flex mb-5">
            <img :src="require('@/assets/documento.png')" alt="Imagen 3" class="img-fluid me-3" style="width: 100px;">
            <div>
              <h4 class="txt-v txt-valores-title">PASIÓN POR LOS RESULTADOS</h4>
              <p class="txt-v">Estamos siempre en búsqueda de la excelencia y alto rendimiento</p>
            </div>
          </div>
          <div class="d-flex mb-5">
            <img :src="require('@/assets/corazon.png')" alt="Imagen 4" class="img-fluid me-3" style="width: 100px;">
            <div>
              <h4 class="txt-v txt-valores-title">RESPONSABILIDAD SOCIAL</h4>
              <p class="txt-v">Tenemos un compromiso social con nuestros clientes, proveedores, colaboradores y el medio ambiente</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

<section id="footer" class="footer">
    <div class="social-icons">
      <a href="https://www.instagram.com/rivieracondocare?igsh=MXZvNmh6bGt2dzhidg==" target="_blank" class="icon">
        <i class="bi bi-instagram"></i>
      </a>
      <a href="https://www.facebook.com/RivieraCondoCare?mibextid=ZbWKwL" target="_blank" class="icon">
        <i class="bi bi-facebook"></i>
      </a>
      <a href="https://wa.me/+529848045024?text=Hola%20me%20gustaría%20más%20información%20C" target="_blank" class="icon">
        <i class="bi bi-whatsapp"></i>
      </a>
      <a href="mailto:Info@rivieracondocare.com" target="_blank" class="icon">
        <i class="bi bi-envelope-fill"></i>
      </a>
      <a href="https://www.linkedin.com/in/riviera-condocare-44a3b4309/" target="_blank" class="icon">
        <i class="bi bi-linkedin"></i>
      </a>
      
    </div>
  </section>

</template>

<script>
import NavbarComponente from '@/components/Navbar.vue'
import CarouselServicios from '@/components/Carousel.vue'
import TeamCarousel from '@/components/TeamCarousel.vue'

export default {
  name: 'HomeView',
  components: {
    NavbarComponente,
    CarouselServicios,
    TeamCarousel
  }
}
</script>

<style scoped>
.quienessomos {
  background-color: #0F4020;
  height: 440px;
}

.servicios {
  background-color: #FFFFFF;
  height: 670px;
}

.equipo {
  background-color: #798940;
  height: 700px;
}

.valores {
  background-color: #FFFFFF;
  height: 650px;
  overflow-x: hidden;
}

/* Navbar -Dana */
.nav {
  position: relative;
  width: 100%;
  height: 1000px;
  background: url('../assets/logop.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.txt-administracion {
  color: #F29E38;
  margin: 4%;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 400%;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 716;
  font-style: normal;
}

.txt-cuidamos {
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 70%;
  margin-left: 4%;
}

/* End Navbar */

/* quienes somos */
.txt-orange-quienes {
  font-family: "Montserrat", sans-serif;
  color: #f8914b;
}

.txt-white-quienes {
  font-family: "Montserrat", sans-serif;
  color: white;
  text-align: justify;
  font-size: 150%;
}

.img-quienes {
  width: 80%;
}

/* nuestro equipo */
.txt-white-equipo {
  font-family: "Montserrat", sans-serif;
  color: white;
}

/*valores */
.txt-v{
  font-family: "Montserrat", sans-serif;
  text-align: left;
}
.txt-orange-valores{
  color: #F29E38;
}
.txt-valores-title{
  color: #F29E38;
  display: flex;
  justify-content: left;
}
.value {
  width: 30%;
  margin: 10px;
}

.value-icon {
  font-size: 50px;
  color: #004d1a;
}

.value-title {
  color: #ff7f00;
  font-weight: bold;
}

.valores {
  padding: 20px 0;
}

.valores h2 {
  font-size: 2rem;
}

.valores img {
  border-radius: 5px;
}

.valores h3 {
  margin-top: 0;
}

.valores p {
  margin-bottom: 0;
}
/* footer */
.footer {
  background-color: #0F4020;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.social-icons {
  display: flex;
  justify-content: space-around;
  width: 300px;
}

.icon {
  background-color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #333;
  transition: transform 0.3s ease;
}

.icon:hover {
  transform: scale(1.1);
}

.icon i {
  font-size: 24px;
  color: #004d1a;
}


/* Media query para pantallas pequeñas */
@media (max-width: 1200px) {
  .txt-administracion {
    font-size: 350%;
  }

  .img-quienes {
    width: 80%;
  }

  .txt-white-quienes {
    font-size: 130%;
  }
}

@media (max-width: 992px) {
  .txt-administracion {
    font-size: 300%;
  }

  .nav {
    width: 100%;
    height: 700px;
  }

  .txt-white-quienes {
    font-size: 100%;
  }
}

@media (max-width: 768px) {
  .txt-administracion {
    font-size: 250%;
  }

}

@media (max-width: 576px) {
  .txt-administracion {
    font-size: 200%;
  }

  .txt-cuidamos {
    font-size: 39px;
  }

  .img-quienes {
    width: 60%;
  }
}

@media (max-width: 480px) {
  .txt-administracion {
    font-size: 200%;

  }

  .nav {
    width: 100%;
    height: 500px;
  }
}

@media (max-width: 360px) {
  .txt-administracion {
    font-size: 200%;
  }
}
</style>
