<template>
    <carousel :items-to-show="1" :loop="true">
        <slide v-for="(group, index) in groupedItems" :key="index">
            <div class="slide-container">
                <section class="pb-2">
                    <div class="container">
                        <div class="row g-4">
                            <div class="col-4 img-space" v-for="(item, idx) in group" :key="idx">
                                <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
                                    <div class="mainflip">
                                        <div class="frontside">
                                            <div class="card same-size">
                                                <div class="card-body text-center">
                                                    <p><img class="img-fluid" :src="item.image" alt="card image"></p>
                                                    <h4 class="card-title">{{ item.text }}</h4>
                                                    <p class="card-text">{{ item.puesto }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="backside">
                                            <div class="card same-size">
                                                <div class="card-body text-center my-5 py-4">
                                                    <p class="card-text-back">{{ item.definicion }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </slide>

        <template #addons>
            <navigation />
            <pagination />
        </template>
    </carousel>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
    name: 'App',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data() {
        return {
            items: [
                { image: require('@/assets/daniela.jpg'), text: 'DANIELA ARJONA', puesto: 'DIRECTORA DE OPERACIONES', definicion: 'Poseo una experiencia laboral de quince años en el sector de servicios, con un enfoque en los últimos ocho años de especialización en áreas de gestión del talento humano.' },
                { image: require('@/assets/wendy.jpg'), text: 'WENDY PACHECO', puesto: 'DIRECTORA DE ADMINISTRACIÓN', definicion: 'Atención al cliente desde hace diez años y la mitad de ellos enfocada en la administración de condominios y en la elaboración y supervisión de presupuestos para la optimización de recursos.' },
                { image: require('@/assets/daniel.jpg'), text: 'DANIEL CASTRO', puesto: 'DIRECTOR LEGAL', definicion: 'Abogado bilingüe con especialidad corporativa y patrimonial. Dieciocho años de experiencia en derecho inmobiliario en la riviera maya y altos estándares de servicio a clientes nacionales y extranjeros.' },
                { image: require('@/assets/falconi.jpg'), text: 'MIGUEL FALCONI', puesto: 'GERENTE DE MANTENIMIENTO', definicion: 'He trabajado 25 años como especialista eléctrico y plomero, para empresas como Pemex y hoteles como  El Dorado, Blue Bay y Fairmont Mayakoba.' },
                { image: require('@/assets/tadeo.jpeg'), text: 'TADEO ARJONA', puesto: 'GERENTE DE PROYECTOS', definicion: 'Soy un profesional en administración y finanzas, con un firme compromiso hacia la excelencia en el servicio al cliente.' },
                { image: require('@/assets/hugo.jpg'), text: 'HUGO APARICIO', puesto: 'CONTADOR', definicion: 'Ejerciendo profesionalmente desde 2016 en diferentes despachos contables y fiscales, amplio conocimiento en leyes fiscales, laborales y de seguridad social.' }
            ],
            windowWidth: window.innerWidth
        };
    },
    computed: {
        groupedItems() {
            let itemsPerGroup = this.windowWidth < 992 ? 1 : 3;
            let groups = [];
            for (let i = 0; i < this.items.length; i += itemsPerGroup) {
                groups.push(this.items.slice(i, i + itemsPerGroup));
            }
            return groups;
        }
    },
    mounted() {
        window.addEventListener('resize', this.updateWindowWidth);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateWindowWidth);
    },
    methods: {
        updateWindowWidth() {
            this.windowWidth = window.innerWidth;
        }
    }
}
</script>

<style scoped>
.img-fluid {
    border-radius: 0%;
}
.card-title{
    font-family: "Montserrat", sans-serif;
    font-size: large;
    color: #0F4020;;
}
.card-text{
    font-family: "Montserrat", sans-serif;
    font-size: medium;
    color: #0F4020;
}
.card-text-back{
    padding-top: 30%;
    font-family: "Montserrat", sans-serif;
    font-size: medium;
    color: #ffffff;
}

.swiper-slide img {
    width: 100%;
    height: auto;
}

.img-size {
    height: 450px;
    width: 300px;
    border-radius: 4%;
    object-fit: cover;
}

.slide-container {
    position: relative;
    text-align: center;
}

.image-text {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.121);
    color: white;
    padding: 5px;
}

/* cards */
#team {
    background: #eee !important;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: #108d6f;
    border-color: #108d6f;
    box-shadow: none;
    outline: none;
}

.btn-primary {
    color: #fff;
    background-color: #007b5e;
    border-color: #007b5e;
}

section .section-title {
    text-align: center;
    color: #007b5e;
    margin-bottom: 50px;
    text-transform: uppercase;
}

#team .card {
    border: none;
    background: #ffffff;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    border-radius: .25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.mainflip {
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 1s;
    -moz-transition: 1s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
}

.frontside {
    position: relative;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    z-index: 2;
    margin-bottom: 30px;
}

.backside {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(5, 90, 4);
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 1s;
    -moz-transform-style: preserve-3d;
    -o-transition: 1s;
    -o-transform-style: preserve-3d;
    -ms-transition: 1s;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
}

.frontside .card,
.backside .card {
    min-height: 312px;
    width: 300px;
    height: 450px;
}

.backside .card {
    color: white;
    background-color: #0F4020;
}

.frontside .card .card-body img {
    width: 250px;
    height: 310px;
    border-radius: 2%;
    object-fit: cover;
    font-family: "Montserrat", sans-serif;
}

.same-size {
    width: 300px;
    height: 450px;
}
</style>