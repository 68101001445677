<template>
    <nav class="navbar navbar-expand-lg">
        <div class="container-fluid size-nav">
            <img class="rcc-image" :src="require('@/assets/RCC-color.png')" alt="Logo RCC" />

            <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
                <ul class="navbar-nav ">
                    <li class="nav-item px-5">
                        <a class="nav-link color-txt" aria-current="page" href="#quienes-somos">INICIO</a>
                    </li>
                    <li class="nav-item px-5">
                        <a class="nav-link color-txt" href="#servicios">SERVICIOS</a>
                    </li>
                    <li class="nav-item px-5">
                        <a class="nav-link color-txt" href="#equipo">NUESTRO EQUIPO</a>
                    </li>
                    <li class="nav-item px-5">
                        <a class="nav-link color-txt" href="#valores">NUESTROS VALORES</a>
                    </li>
                    <li class="nav-item px-5">
                        <a class="nav-link color-txt" href="#footer">CONTACTO</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'NavbarComponente'
}
</script>

<style scoped>

.rcc-image{
    width: 170px;
}
.size-nav{
    height: 70px;
}
.color-txt{
    font-family: "Cinzel", serif;
    font-optical-sizing: auto;
    color: #F2DC99;
    font-weight: bold;
}
.color-txt:hover{
    color: #c8ae61;
}
@media (max-width: 1170px) {
    .navbar-nav  {
        display: none; 
    }
}
</style>