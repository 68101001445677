<template>
    <carousel :items-to-show="itemsToShow" :loop="true">
        <slide v-for="(item, index) in items" :key="index">
            <div class="slide-container">
                <img :src="item.image" :alt="'Slide ' + index" class="carousel-image img-size" />
                <div class="image-text">{{ item.text }}</div>
            </div>
        </slide>

        <template #addons>
            <navigation />
            <pagination />
        </template>
    </carousel>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
    name: 'App',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data() {
        return {
            items: [
                { image: require('@/assets/jardineria.jpg'), text: 'JARDINERÍA' },
                { image: require('@/assets/alberca.jpg'), text: 'MANTENIMIENTO DE ALBERCAS' },
                { image: require('@/assets/limpieza.jpg'), text: 'LIMPIEZA Y MANTENIMIENTO GENERAL' },
                { image: require('@/assets/admin.jpg'), text: 'ADMINISTRACIÓN DE CUENTAS' },
                { image: require('@/assets/gestiones.jpg'), text: 'GESTIONES LEGALES' },
                { image: require('@/assets/presupuestos.jpg'), text: 'ELABORACIÓN DE PRESUPUESTOS' },
                { image: require('@/assets/informes.jpg'), text: 'INFORMES MENSUALES' },
                { image: require('@/assets/coordinacion.jpg'), text: 'COORDINACIÓN DE ASAMBLEAS CONDOMINALES' },
                { image: require('@/assets/cliente.jpg'), text: 'SERVICIO ESPECIALIZADO AL CLIENTE' }
            ],
            itemsToShow: 1.5
        };
    },
    mounted() {
        this.updateItemsToShow();
        window.addEventListener('resize', this.updateItemsToShow);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateItemsToShow);
    },
    methods: {
        updateItemsToShow() {
            if (window.matchMedia('(max-width: 576px)').matches) {
                this.itemsToShow = 1;
            } else if (window.matchMedia('(max-width: 768px)').matches) {
                this.itemsToShow = 1.5;
            } else if (window.matchMedia('(max-width: 992px)').matches) {
                this.itemsToShow = 2;
            } else {
                this.itemsToShow = 3;
            }
        }
    }
}
</script>

<style scoped>
.swiper-slide img {
    width: 100%;
    height: auto;
}
.img-size {
    height: 450px;
    width: 300px;
    border-radius: 4%;
    object-fit: cover;
}
.slide-container {
    position: relative;
    text-align: center;
}
.image-text {
    font-family: "Montserrat", sans-serif;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    padding: 5px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.979);
}
</style>
